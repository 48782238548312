
.playlistModal {
  border: 1px solid var(--color-txt-label) !important;
  width: 700px;
  div {
    margin-top: 5px;
  }

  input[type=text] {
    padding:6px;
    margin-top: 2px;
    width: 150px;
  }

  .headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 0;

    div {
      margin-bottom: 0;
    }
  }
  .info {
    display: flex;
    justify-content: space-around;
    .left {
      display: flex;
      flex-direction: column;
    }
    .right {
      display: flex;
      flex-direction: column;
    }
  }
  .tracksContainer {
    display: flex;
    margin-bottom: 6px;
    justify-content: space-between;
    .working {
      display: flex;
      flex-direction: column;
    }
    .chooseFrom {
      display: flex;
      flex-direction: column;
    }
    .playlistDisplay {
      max-height: 600px;
    }
  }
}
