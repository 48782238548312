
.closeIcon {
  color: var(--color-txt-label);
  position: absolute;
  width: 10px;
  top: 2px;
  right: 6px;
  cursor: pointer;

  &.mini {
    top: -4px;
    right: 2px
  }
}
.playlistTitle {
  background-color: var(--color-bg-tree-row-playlist);
  margin: 0 !important;
  justify-content: space-between;
}
button.trash {
  min-width: initial !important;
  padding: 2px !important;
  height: 26px;
  width:26px;
  border-radius: 12px;
  background-color: initial !important;
  margin: 0 !important;
  &:disabled {
    color: var(--color-bg-tree-row-playlist);
  }
}
label.radio {
  width: initial !important;
  margin: 3px;
}

.setting {
  padding-left: 7px;
}

.sectionBody {
  padding-left: 7px;
  padding-bottom: 5px;
}

.textInputWithLabel {
  padding:6px;
  margin-top: 2px;
  width: 210px;
}
.numberInputWithLabel {
  width: 30px;
  text-align: right;
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.textLabel {
  width:300px;
  border-style: none;
  padding:6px;

  &.slug {
    width: auto;
  }
}

.inviteDisplay {
  display: flex;
  flex-direction: column;
  // margin: 0px !important;
  padding-left: 6px;
  .invite {
    margin: 0px;
    margin-bottom: 2px;
    &.header {
      font-style: italic;
      font-size: smaller;
      background-color: initial;
      padding-bottom: 1px;
    }
    background-color: var(--color-bg-list);
    .matchInvite {
      display: flex;
      margin: 5px;
      padding-left: 3px;
      padding-right: 3px;
      .heading {
        font-size: x-small;
      }
      .left {
        flex: 3;
        align-items: baseline;
        flex-direction: column;
        div {
          margin: 0px;
          display: flex;
        }
        .title {
          color: var(--color-txt-title) !important;
          justify-content: flex-start;
        }
        .owner {
          color: $color-txt-match-owner;
          font-size: smaller;
        }
      }
      .center {
        flex: 1;
        flex-direction: column;
        font-size: x-small;
        div {
          margin: 0px;
        }
        &.expired {
          color: red;
        }
      }
      .right {
        flex: 2;
        display: flex;
        align-items: center;

        button {
          flex: 1;
          margin: 2px;
        }
      }
    }
  }
}

.identitySection {
  img {
    width: 20px;
    vertical-align: bottom;
    margin-right: 4px;
    border-radius: 2px;
  }
  .nameRow {
    display: flex;
    align-items: baseline;
  }
  button {
    height: 20px;
    font-size: 10px;
    margin-bottom: 2px;
    margin-top: 3px;
    margin-left: 17px;
    max-width: fit-content;
    border: 1px solid var(--color-bg-sidebar);

  }
}

.invitesDisplay2 {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: var(--color-bg-input);
  height:50px;
  overflow-y: scroll;
  margin-left: 8px;
  border-radius: 3px;
  color: white;
  font-size: 14px;
  div {
    cursor: pointer;
    margin-top: 0 !important;

    &:hover {
      background-color: #15c2dc;
    }
  }
  .invite {
    display: flex;
    justify-content: space-between;
    padding: 2px;

    .key { width: 50px }
    .status { font-style: italic }

    &.expired {
      font-style: italic;
      .status { font-style: initial }
    }
  }
}

.playlistDisplay2 {
  display: flex;
  border-radius: 0px !important;
  margin-right: 2px;
  flex-direction: column;
}
.trackDisplay2 {
  display: flex;
  flex-direction: column;
  margin-top: 0px !important;
}
.playlistDisplay {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--color-bg-input);
  min-height:200px;
  max-height: 350px;
  min-width: 320px;
  overflow-y: scroll;
  margin-top: 2px;
  margin-left: 8px;
  border-radius: 3px;

  &.matches {
    color: white;
    padding-left: 8px;
  }

  .track {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex: 4;
    cursor: pointer;

    div {
      padding: 0;
      color: $color-txt-modal;
    }

    .artist {
      color: var(--color-txt-title);
      font-size: smaller;
    }
  }

  div {
    margin-bottom: 0px !important;
    padding: 3px;
    padding-top: 0px;
    display: flex;
  }

  label {
    width: 100% !important;
    display: initial !important;
    cursor: pointer;
    color: $color-txt-modal;
  }

  .otherOwner {
    font-size: x-small;
    flex: 1;
    font-style: italic;
    color: $color-txt-modal;
  }
}

.newPlaylist {
  font-style: italic;
}

.trackMembership {
  vertical-align: top;
}

.joinMatchModal {
  .prompt {
    font-size: smaller;
    font-style: italic;
    padding: 2px;

    &.isError {
      color: $color-txt-modal-error;
    }
  }
  .textInputWithLabel {
    width: 275px;
  }
  .join {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;

    .status {
     display: flex;
      flex-direction: column;
    }
  }
}

.playerInviteModal {
  border: 1px solid var(--color-txt-label) !important;

  .modalBody {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: baseline;
    }
    .editSection {
      flex-direction: column;
    }
    .directInviteSection {
      flex-direction: column;
      padding-top: 6px;

      label {
        width: 135px;
      }
    }
  }

  .link {
    padding-bottom: 5px;

    .copy {
      min-width: initial;
      height: 25px;
      width: 22px;
      margin-left: 9px;
      padding: initial;
    }

    .copyConfirmation {
      color: var(--color-txt-label);
      font-style: italic;
      font-size: smaller;
    }
  }

  textarea {
    width: 30em;
    background: var(--color-bg-list);
    color: var(--color-txt-label);
    padding: 5px;
    margin-top: 4px;
    margin-bottom: 6px;
  }
}

.matchInfoModal {
  border-radius: 12px;
  border: 6px solid $color-border-modal;
  animation: move 0.4s;
  background-color: var(--color-bg-modal);
  z-index: 200000;
  padding: 8px 30px 15px 30px;
  width: unset !important;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  .playlistModal {
    left:200px;
  }
  .twoCol {
    display: flex;

    .left {
      display: flex;
      flex-direction: column;
      padding-right: 15px;

      .players {
        margin-top: 0px;

        button {
          margin-top: 4px;
          margin-bottom: 2px;
        }

        .playersDisplay {
          display: flex;
          flex-direction: column;
          padding-bottom: 5px;
          background-color: var(--color-bg-input);
          overflow-y: scroll;
          min-width: 250px;
          height: 275px;
          margin-top: 5px;
          margin-left: 8px;
          border-radius: 3px;
          padding-left: 4px;
          padding-right: 4px;

          .player {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            align-items: baseline;
            color: $color-txt-modal;
            margin-top: 0px;
            .left {
              margin-top: 0px;
              display: flex;
              flex-direction: row;
              .title {
                font-style: italic;
                &.isGuest {
                  cursor: pointer;
                  font-style: unset;
                }
                margin-top: 0px;
                margin-left: 8px;

                .id {
                  color: var(--color-txt-title);
                  font-size: smaller;
                }
              }
            }
            .score {
              height: fit-content;
              color: black;
              padding: 2px;
              border-radius: 2px;
              background-color: var(--color-bg-tree-row-playlist);
            }
          }
        }
      }
    }
    .right {
      flex-direction: column;
      border-left: 1px dotted var(--color-border-modal);
      padding-left: 10px;
      min-width: 350px;

      .bottom {
        margin-top: 43px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: smaller;
        }
      }
    }
  }
  div {
    margin-top: 5px;
  }

  .visibility {
    display: flex;
    align-items: baseline;
    margin:0;

    .labeledCheckbox label {
      width: auto;
      padding-right: 10px;
    }
    .help {
      padding-left: 0;
    }
  }

  .hostVisibility {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    margin:0;

    label {
      width: 95px;
    }
    div {
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      width: 187px;
    }
  }
  .buttons {
    margin-top: 9px;
    margin-bottom: 10px;
  }
}

.userSettingsModal {
  width: 410px !important;

  div {
    margin-top: 5px;
  }
  input[type=range] {
    width:150px;
    cursor:pointer;
    margin-left:9px;
    margin-right:9px;
    margin-bottom:8px;
    vertical-align: sub;
  }
  .heading {
    text-decoration: underline;
  }
  .match {
    display: flex;
    align-items: center;

    .right {
      display: flex;
      align-items: baseline;
      div {
        margin-top: 0px !important;
      }
      .title {
        color: $color-txt-modal !important;
      }

      .owner {
        color: var(--color-txt-title);
        font-size: smaller;
      }

    }
  }

  .players {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .playersDisplay {
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: var(--color-bg-input);
      overflow-y: scroll;
      min-width: 350px;
      margin-top: 2px;
      margin-left: 8px;
      border-radius: 3px;

      .player {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        flex: 4;
        cursor: pointer;

        div {
          padding: 0;
          color: $color-txt-modal;
        }

        .id {
          color: var(--color-txt-title);
          font-size: smaller;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-around;
  }
}

.modalHeading {
  text-align: center;
  color: var(--color-txt-label) !important;
  font-size: 14px;
  padding-bottom: 10px;

  &.small {
    font-size: 12px;
    padding-bottom: 0px;
  }
}

.modalContainer {
  border-radius: 12px;
  border: 6px solid var(--color-bg-modal);
  animation: move 0.4s;
  color: var(--color-txt-label);
  background-color: var(--color-bg-modal);
  position: fixed;
  z-index: 200000;
  top: 2%;
  padding: 8px 30px 15px 30px;
  width: 400px;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  outline: none;

  label {
    display:inline-block;
    width: 95px;
    &.wide {
      width: 100%;
    }
  }

  &.playerInviteModal, &.joinMatchModal {
    top: 20%;
  }

  &.nameChangeModal {
    top: 20%;
    left: 33%;
    padding-bottom: 5px;

    > .status {
      margin: 6px;
      color: $green-300;
    }
    button {
      width: fit-content;
      margin-top: 5px;
    }
  }
  &.playerEditModal {
    border: 1px solid white;
  }
}

.inviteError {
  font-size: smaller;
  span {
    color: red;
  }
}

.errorModal {
  z-index: 300000;
  padding: 8px 30px 15px 30px;
  top: 30%;
  padding: 8px 8px 2px 8px;
  width: 60%;

  .modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 10px 10px;

    div {
      background: $color-bg-blast-error;
      margin-bottom: 12px;
      padding: 10px;
      width: 100%;
    }

    button {
      max-width: 10px;
    }
  }
}

.helpModal {
  border-radius: 12px;
  border: 6px solid var(--color-bg-modal);
  animation: move 0.4s;
  background-color: var(--color-bg-modal);
  position: fixed;
  z-index: 200000;
  max-height: 64%;
  padding: 8px 30px;
  width: 800px;
  display: flex;
  flex-direction: column;
  color: var(--color-txt-label);
  font-weight: normal;
  outline: none;

  p {
    font-style: italic;
    text-align: center;
    margin-block-end: 0;
    margin-block-start: 4px;
  }

  ul {
    color: inherit;
    padding-bottom: 10px;
  }

  li {
    list-style: initial;
    padding-top: 10px;
  }

  iframe {
    height: 530px;
    margin-bottom: 8px;
  }

  .icon {
    margin-right: 5px;
  }
}

.modalBackdropContainer {
  position: absolute;
  left: 0;
  background-color: $color-bg-modal-overlay;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  display: flex;
  border-radius: 9px;

  .playlistModal {
    top: 5%;
  }
  button {
    background-color: #8db2b7;
    margin-right: 5px;
    padding: 3px 10px 3px 10px;
  }
}

.termsOfUseModal {
  border-radius: 12px;
  border: 6px solid $color-border-modal;
  animation: move 0.4s;
  background-color: var(--color-bg-modal);
  position: fixed;
  z-index: 200010;
  max-height: 64%;
  padding: 8px 30px 15px 30px;
  width: 500px;
  display: flex;
  flex-direction: column;
  color: $color-txt-modal;
  font-weight: normal;

  iframe {
    border: 0;
    min-height: 300px;
  }

  p {
    font-style: italic;
    text-align: center;
    margin-block-end: 20px;
    margin-block-start: 4px;
  }

  .modalFooter {
    margin-top: 20px;
    flex-direction: column;
  }
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.linksBox {
  display: flex;
  flex-direction: row;
  border: 1px solid $color-border-modal;
  border-radius: 4px;
  width: 100%;
}

.linksBox div {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px !important;
  margin-top: 2px;
}

.linksBox a {
  color: var(--color-txt-title);
  text-decoration: none;
  font-style: italic;
}

.linksBox img {
  height: 24px;
}

.lyricEntry {
  flex: 8;
  display: flex;
}

.lyricEditContainer {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    min-width: 50%;
    margin-bottom:10px;
    margin-left:5px;
    margin-right:5px;
  }

  button {
    background-color: var(--color-bg-button);
    margin-right: 5px;
    padding: 3px 10px 3px 10px;
  }
}

.lyricEditText {
  flex: 1;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 22px;
  margin-top: 7px;
  line-height: 1.5;
}

.localControls {
  display: flex;
  width: 100%;
  padding-top: 5px;
}

.dirty-div {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  width: 30%;

  div {
    display: flex;
    margin-bottom: 0px !important;
  }

  label {
    min-width: 55px;
  }

  input[type=checkbox] {
    margin-right: 5px;
  }
}

.uploadProgressWrapper {
  min-width: 110px;
  height: 19px;
  margin-left: 6px;
  margin-top: 2px;
  background-color: inherit;
  border: none;
}

.uploadProgressWrapper.show {
  background-color: $color-bg-progress-bar-bg;
  border: 1px solid $color-border-progress-bar;
}

.uploadProgress {
  background-color: $color-bg-progress-bar-progress;
  height:19px;
  color: $color-bg-progress-bar-progress;
  font-size:14px;
  padding-top:0px;
  width: 0;
  margin-bottom: 0px !important;
}

.uploadProgress.show {
  background-color: inherit;
}

.uploadProgress.done {
  background-color: inherit;
  min-width: 200px;
  color: $color-bg-progress-bar-bg;
  font-size: small;
  align-items: baseline !important;
}

.trackGallery {
  color: var(--color-txt-title);
  text-transform: lowercase;
  padding: 12px;

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
   &:focus {
    .track {
      border-color: #459bac;
      border-style: ridge;
      border-width:6px;
    }
    outline: none;
  }
}
  .disabled {
    opacity: 0.4;
    display: block;
  }
  .emptySquare {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .current {
    .track {
      border-color: #459cac;
    }
  }
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 25px;
  row-gap:35px;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  .track {
border:6px solid white;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: normal;
        color: #000000;
        font-family: Montserrat;
        font-size: 2vw;
        text-transform: none;
        font-weight: 700;
        -webkit-box-shadow: 0px 6px 8px -4px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 6px 8px -4px rgba(0,0,0,0.5);
        box-shadow: 0px 6px 8px -4px rgba(0,0,0,0.5);
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        background-color: white;
    height: 100%;
    &:hover {
      transform: scale3d(1.05, 1.05, 0.1);
    }
    .title {
      height: 100%;
          font-size: 1.4vw;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
          width: 100%;
          -webkit-border-top-left-radius: 6px;
          -webkit-border-top-right-radius: 6px;
          -moz-border-radius-topleft: 6px;
          -moz-border-radius-topright: 6px;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          line-height: 1.1em;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #105765;
      padding-top: 8px;
      padding-bottom: 8px;
      --shine-deg: 45deg;
      overflow: hidden;
      background-color: #85b9c2;
      text-transform: capitalize;
    }
    .artist {
      color: #ffffff;
          background-color: #459bac;
          width:100%;
      font-weight:500;
          font-style: normal;
          text-transform: uppercase;
          font-size: 0.8vw;
      line-height:28px;
      display: flex;
      justify-content: center;
      border-top: 1.5px solid rgb(228 240 242 / 84%);
    }
    .matchNodeInfo {

      width: 100%;

      .prescore {
        display: flex;
        width: 100%;
        align-items: center;
        font-size: 0.9vw;
        color: #a5a5a5;
        font-weight: 600;
        flex-direction: row;
        background: linear-gradient(182deg, rgb(166 162 162 / 60%) 0%, rgb(227 225 225 / 63%) 29%, rgb(253 253 253) 75%, rgb(255 255 255 / 0%) 100%);
      }
      .score {
        display: flex;
        flex-direction: column;
        align-items: center;
            font-size: 0.9vw;
            color: #a1a1a1;
            font-weight: 600;
            width:100%;
            background-color: white;
            -webkit-border-bottom-right-radius: 12px;
            -webkit-border-bottom-left-radius: 12px;
            -moz-border-radius-bottomright: 12px;
            -moz-border-radius-bottomleft: 12px;
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;

        .topScores {
          display: flex;
          overflow-x: scroll;
          max-width: 18vh;
          width: 100%;
          padding: 8px 0px 8px 4px;
          flex-wrap: nowrap;
        }
        .topScore {
          font-size: 0.7vw;
          background-color: var(--color-bg-score-progress-bar);
          border-radius: 4px;
          margin-top:2px;
          margin-right: 6px;
          min-width: 40px;
          padding: 1px;
          color: var(--color-txt-score-progress);
          display: block;
        }
        .points {
          color: #af11af;
        }
        .pointInfo {display:none;
              padding-top: 4px;
              padding-bottom: 4px;
          column-gap: 10px;
          justify-content: center;
              line-height: 24px;
              background-color: #efefef;
              width: 100%;
          font-size: 0.75vw;
          font-weight:400;
        }
      }
      span {

      }
    }
  }
}

    .blastButton {
      margin-right: 0px;
      opacity: 100%;
      animation: none;
    }

.totalpoints{    background-color: #c4e3e9;
  align-items: center;
  width: 100%;
  font-size: 0.8vw;
  color: #568b9b;
  font-weight: 500;
  display: flex;
  justify-content: center;
  border-radius: 0px 0px 6px 6px;}

span.points{
  font-size: 1.2vw;
  color: #3f94a4;
  font-weight: 700;}

span.duration{    font-size: 0.9vw;
  //color: #545454;
  margin-left: 12px;
  font-weight: 700;
  margin-left: 4px;
  padding-left:4px}
span.wordcountamount{    font-size: 0.9vw;
  color: #545454;
  margin-left: 12px;
  font-weight: 700;
  margin-left: 4px;
padding-left:4px}
span.pointrspertrack{    font-size: 0.9vw;
  color: #545454;
  margin-left: 12px;
  font-weight: 700;
  margin-left: 4px;
  padding-left:4px}

.previousscores{
  color: #a5a5a5;
  font-size: 0.5vw;
  font-weight: 400;
  /* width: 100%; */
  padding: 4px 0px 0px 14px;
  text-align: left;
}

.perfectscore{
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 4px;
  width: 33%;
  font-size: 0.7vw;
  border-radius: 0px 0px 0px 6px;
}
.wordcountamount{
  display: flex;
  flex-direction: column;
  font-size: 0.7vw;
  padding: 4px;
  width: 33%;
  padding: 6px;
  background-color: #add8e1;
}
.pointspertrackamount{
  display: flex;
  flex-direction: column;
  font-size: 0.7vw;
  padding: 4px;
  width: 33%;
}

.wordcountnumber{      font-size: 1vw;
  font-weight:700;}
.pointspertrack{      font-size: 1vw;
  font-weight:700;}

