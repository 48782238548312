
.trackInfoModal {
  width: 700px;
  .localAudioWrapper, .localImageWrapper {
    button {
      margin-left: 10px;
    }
  }
  div {
    margin-top: 5px;
  }

  .fileMissing {
    color: $color-txt-modal-error !important;
    font-weight: bold;
    border: 1px solid $color-txt-modal-error !important;
  }
  button.adopt {
    margin-left: 7px;
  }
  .info {
    display: flex;
    justify-content: space-around;
    .left {
      display: flex;
      flex-direction: column;
    }
    .right {
      display: flex;
      flex-direction: column;
    }
  }
  .ownershipContainer {
    display: flex;
    margin-bottom: 6px;
    justify-content: space-between;
    .working {
      display: flex;
      flex-direction: column;
    }
    .chooseFrom {
      display: flex;
      flex-direction: column;
    }
    .playlistDisplay {
      max-height: 600px;
    }
  }
}
