
.labeledCheckbox{
}


.leaderboardModal {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 80%;
  top: 4%;
  min-width: 500px;

  .blastButton.left {
    margin: -11px;
  }
  .modalHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      flex-direction: column;
      .logo {
        align-items: center;
      }
    }
    .center {
      flex-direction: row;
      align-items: center;
      background-color: #ffffff5e;
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 36px;
      padding-top: 6px;
      padding-bottom: 6px;
      margin-bottom: 6px;
      .round {
        justify-content: space-around;
        .player {
          margin: 0px 20px;
          align-items: center;
          flex-direction: row-reverse;
          &.left {
            flex-direction: row;
          }
          .name {
            font-size: large;
            margin-right: -24px;
            text-align: right;
          }
        }
      }
    }
    .right {
      align-items: center;
      .blastButton {
        height: 35px;
        width: 60px;
      }
    }
    .matchTitle {
      font-size: 2.0em;
      font-family: Montserrat;
      font-weight: 700;
      color: #32595e !important;
    }
    select {

      margin: 4px 0px;
      font-size: 0.9vw;
      color: white;
      border-radius: 24px;
      border: 2px solid #cadcde;
      font-family: Montserrat;
      font-weight: 500;
      background-color: #678A8E;
      padding:6px;
      padding-left:12px;
      padding-right:8px;
      margin-left:16px;
      box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.5);
    }
  }
  div {
    display: flex;
  }
  .visibility {
    justify-content: flex-start !important;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    label {
      width: unset;
      margin-right: 4px;
      font-size: 11px;
    }
  }
  // TODO: way too much duplicated here, fix
  .players {
    min-height: 50px;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    color: #53797d;
    text-transform: uppercase;
    letter-spacing: 0.2rem;

    button {
      margin-top: 4px;
      margin-bottom: 2px;
    }

    .playersDisplay {
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
      background-color: var(--color-bg-tree-row-player);
      overflow-y: scroll;
      width: 100%;
      min-width: 450px;
      height: 100%;
      margin-top: 5px;
      margin-left: 8px;
      border-radius: 3px;
      padding-left: 4px;
      padding-right: 4px;
      .sortable-list-item {
        background-color: var(--color-bg-tree-row-player);
        border: unset;
      }
      .player {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: var(--color-txt-title);
        margin-top: 0px;
        .left {
          margin-top: 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .rank {
            color: var(--color-txt-label);
            font-size: x-large;
          }
          .title {
            font-size: xx-large;
            font-style: italic;
            margin-left: 8px;

            &.isGuest {
              cursor: pointer;
              font-style: unset;
            }
            .id {
              color: var(--color-txt-label);
              font-size: medium;
            }
          }
        }
        .score {
          color: black;
          padding: 2px;
        }
      }
    }
  }
}
